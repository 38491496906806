<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游冲绳必备的景点套票，精选冲绳山多个人气设施与美食店家，一週内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
           EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项设施</span><br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始使用，所有票劵也将同步开通 。(<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokinawa/zh/havefun_title_sc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfokinawa/zh/oguri_kimono_salon_SC.jpg'),
          title: ['Oguri Kimono Salon和服体验 1000日元优惠券 (大人限定)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒900-0014 冲绳県那霸市松尾2-3-13 2F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['http://oguri-kimono.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。 (仅适用于高中生以上) '] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfokinawa/zh/chibai_SC.jpg'),
          title: ['烧肉HORUMON 赤白 1000日元优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒900-0033 冲绳県那霸市久米2丁目20-21八木大楼 1楼'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yakinikuhorumon_akashiro/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokinawa/zh/SZG_SC.jpg'),
          title: ['冲绳美丽海水族馆入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒905-0206 冲绳县国头郡本部町字石川1421-5 （海风MARKET本部店）'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://churaumi.okinawa/sc/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※台风等不可抗力因素导致公共交通暂停运行情况也将暂停营业。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokinawa/zh/LQC_SC.jpg'),
          title: ['冲绳琉球村入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '冲绳县国头郡恩纳村山田1130'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.ryukyumura.co.jp/official/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※台风等不可抗力因素导致公共交通暂停运行情况也将暂停营业。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokinawa/zh/YQD_SC.jpg'),
          title: ['冲绳文化王国入场券套票（玉泉洞・王国村・毒蛇博物公园）'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒901-0616　冲绳县南城市玉城前川1336番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.gyokusendo.co.jp/okinawaworld/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※台风等不可抗力因素导致公共交通暂停运行情况也将暂停营业。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokinawa/zh/zwy_SC.jpg'),
          title: ['东南植物乐园入场券【日间票】'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '冲绳县冲绳市知花2146'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.southeast-botanical.jp/tw/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※台风等不可抗力因素导致公共交通暂停运行情况也将暂停营业。'] },
                { cid: 2, text: ['入园时间：9:00~17:00','<br>※晚间入园请现场另外购票'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokinawa/zh/kl_SC.jpg'),
          title: ['DINO恐龙公园 山原亚热带之森入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '冲绳县名护市中山1024-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.okashigoten.co.jp/zh-CN/subtropical/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※台风等不可抗力因素导致公共交通暂停运行情况也将暂停营业。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokinawa/zh/bl_SC.jpg'),
          title: ['冲绳名护菠萝园入场券（含乘坐菠萝号）'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒905-0005冲绳县名护市为又1195'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.nagopine.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※台风等不可抗力因素导致公共交通暂停运行情况也将暂停营业。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokinawa/zh/Neopark_SC.jpg'),
          title: ['Neopark冲绳 入场券+冲绳轻铁乘车套餐'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒905-0012冲绳县名护市名护4607-41'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.neopark.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※小火车运行时间: 10:00 am ～17:00 pm（每半小时一班，最后班次17:00）'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfokinawa/zh/sllr_SC.jpg'),
          title: ['首里琉染珊瑚染体验1000日圆折价券 (需要预约)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒903-0825 沖縄县那覇市首里山川町 1-54'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.shuri-ryusen.com/']},
                { cid: 2, text: ['预约网站:'],noColor:true},
                { cid: 3, text: ['ahttps://reserve.shuri-ryusen.com/step1']},
                { cid: 4, text: ['<span style="color:red">※预约时，付款方法请选择到店付款的选项</span>'] ,noColor:true },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['全预约制营业，请事先预约。没有事先预约的话，可能会有休业的情况。请务必确认后再使用。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['体验场次：9:30 / 11:00 / 12:30 / 14:00 / 15:30','<br>※体验所需时间约50分钟，请在体验时间前15分钟到场。'] },
                { cid: 4, text: ['有可能会因为人数额满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfokinawa/zh/haidi_SC.jpg'),
          title: ['冲绳那霸水中观光船Orca　1000日圆折价券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '冲縄县那覇市通堂町２−１(那霸码头 旅客等候区 WEST MARINE)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://cn.westmarine.co.jp/top.php']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。'] },
                { cid: 2, text: ['体验所需时间约50～60分钟'] },
                { cid: 3, text: ['若当天乘客比较多的情况，安全起见可能为採用分批进入观察室观赏。'] },
                { cid: 4, text: ['游览船会进行不定期的维修护理，维修护理期间停航。另外，由于天气及海况不可预测（例如：风浪太大船身不稳、海中能见度不佳等），可能当天抵达现场后才通知不适合登船，届时可选择放弃或者改期参加。'] },
                { cid: 5, text: ['有可能会因为人数额满而无法使用的状况，请见谅'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfokinawa/zh/yuyi_SC.jpg'),
          title: ['漫步冲绳那霸美樱和服/浴衣体验　1000日圆折价券 (指定进店时间：11:00)'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒900-0015冲绳县那霸市久茂地1-6-1ART大楼2-A'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.churasakura.okinawa/#']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。'] },
                { cid: 2, text: ['指定进店时间：11:00'] },
                { cid: 3, text: ['如果入店时客人比较多的情况，需要稍等片刻，敬请谅解。'] },
                { cid: 4, text: ['请于当天18:00以前返还，迟到的话会自动转为次日归还，次日返还时需付加每人1100日元，并且出租当天无法取回自己的随身物品，敬请注意。'] },
                { cid: 5, text: ['归还时，租赁品如果有不可修复的污渍（油漆等）、破损（香烟烙烧、缝制部分以外的破损、破洞等）将收取5000日元作为赔偿金，请当地支付。'] },
                { cid: 6, text: ['当天建议穿件方便换装的打底吊带或紧身衣衫。'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfokinawa/zh/heniu_SC.jpg'),
          title: ['冲绳海景餐厅和牛烤肉 牛武士　1000日圆折价券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '中头郡北谷町字美浜34−3 DEPOT CENTRAL 6F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.gyu-samurai.com/']},
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。 (仅适用于以下套餐)',
                '<br>A套餐内容（根据季节稍有变动)<br>前菜2种拼盘、泡菜拼盘、上等牛舌、严选上等牛里嵴、严选上等牛五花、县产阿古猪五花、特选西冷牛排、内脏三种拼盘、海带鸡蛋汤、米饭、甜点、无酒精饮料一杯',
                '<br>B套餐内容（根据季节稍有变动)<br>前菜3种拼盘、本日色拉、泡菜拼盘、上等牛舌、极上霜降牛里嵴、极上霜降牛五花、特选厚切菲力、县产阿古猪五花、海鲜3种拼盘、内脏三种拼盘、海带鸡蛋汤、米饭、甜点、无酒精饮料一杯'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['餐厅可能出现客满而无法使用票券的情况，敬请谅解。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkinawa&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okinawa-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>